import largeView from '../../../Assets/large-view.png'

import './LargeView.css'

function LargeView() {
    return (
        <div className="large_view">
            <img src={largeView} alt="" />
        </div>
    )
}

export default LargeView