import { intervalToDuration } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../../../../store/appCtx';
import Remaining from '../Components/Remaining/Remaining';

const SinglePrayerTime = ({ name, time, current }) => {
	const { language } = useContext(AppContext);
	const navigate = useNavigate();
	const [remainingTime, setRemainingTime] = useState(
		intervalToDuration({
			start: new Date(),
			end: time,
		})
	);

	const timeToFormat = new Intl.DateTimeFormat(language, {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	}).format(time)

	useEffect(() => {
		let interval;
		if (current) {
			interval = setInterval(() => {
				if (time.getTime() - new Date().getTime() < 0) {
					navigate(0);
				} else {
					setRemainingTime(
						intervalToDuration({
							start: new Date(),
							end: time,
						})
					);
				}
			}, 1000);
		}
		return () => clearInterval(interval);
	}, [time, current, navigate]);

	return (
		<>
			{current ? (
				<div className="active-prayer">
					<h3 className="daily-prayer-time">
						{name}
						<span className="time">
							{timeToFormat.slice(0, 6)}
							<span>
								{timeToFormat.slice(6, 8)}
							</span>
						</span>
					</h3>
					<Remaining
						hours={remainingTime?.hours}
						minutes={remainingTime?.minutes}
						seconds={remainingTime?.seconds}
					/>
				</div>
			) : (
				<h3
					className={`daily-prayer-time ${true ? 'daily-prayer-done' : ''
						}`}
				>
					{name}{' '}
					<span className="time">
						{timeToFormat.slice(0, 6)}
						<span>
							{timeToFormat.slice(6, 8)}
						</span>
					</span>
				</h3>
			)}
		</>
	);
};

export default SinglePrayerTime;
