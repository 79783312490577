import { useContext, useEffect, useState } from 'react'


import './DivisionLists.css'
import DivisionWithDistricts from './DivisionWithDistricts/DivisionWithDistricts';
import axios from 'axios';
import { DIVISIONS_API } from '../../../../Utilities/APIs';
import AppContext from '../../../../store/appCtx';

function DivisionLists({setShowModal}) {

    const [divisions, setDivisions] = useState(null)
    const [selectedDivision, setSelectedDivision] = useState('')
    const {language} = useContext(AppContext)

    useEffect(() => {
        async function getAndSetDivisions() {
            const {data} = await axios.get(DIVISIONS_API)
            setDivisions(data)
        }
        getAndSetDivisions()
    }, [])


    return (
        <div className="division_lists">
            <div className="calender">
                <div className="calender_container">
                    <div className="calender_content">
                        {
                            divisions?.map(division => (
                                <DivisionWithDistricts
                                    key={division._id}
                                    division={division._id}
                                    selectedDivision={selectedDivision}
                                    setSelectedDivision={setSelectedDivision}
                                    name={
                                        language === 'default'
                                        ?
                                        division.name
                                        :
                                        division.bngName
                                    }
                                    setShowModal={setShowModal}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DivisionLists