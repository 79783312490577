import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { DISTRICTS_API, RAMADAN_TIMES_API } from '../../../Utilities/APIs';
import {
	increaseMinutes,
	timeInDateFormat,
} from '../../../Utilities/Functions/TimeInDateFormat';
import DownIcon from '../../svg/DownIcon';
import DateRow from './DateRow/DateRow';

import { Helmet } from 'react-helmet-async';
import AppContext from '../../../store/appCtx';
import Loading from '../../Partials/Loading/Loading';
import './Calender.css';

function Calender() {
	const { loading, language, setLoading, selectedDistrict } =
		useContext(AppContext);
	const [allTimes, setAllTimes] = useState(null);
	const [tab, setTab] = useState(1);

	const [timeDifference, setTimeDifference] = useState(0);

	useEffect(() => {
		async function getAndSetDistrictDetails() {
			const { data } = await axios.get(DISTRICTS_API + selectedDistrict);
			setTimeDifference(data.timeDifference);
		}

		getAndSetDistrictDetails();
	}, [selectedDistrict]);

	useEffect(() => {
		const getAndSetTodayTomorrowTimes = async () => {
			const { data } = await axios.get(RAMADAN_TIMES_API);
			setAllTimes(data);
			const todaysDate = new Date().getDate();
			if (todaysDate > 3 && todaysDate <= 12) {
				setTab(2);
			}
			if (todaysDate > 12 && todaysDate < 22) {
				setTab(3);
			}
			setLoading(false);
		};
		getAndSetTodayTomorrowTimes();
	}, [setLoading]);

	return (
		<>
			<Helmet>
				<title>Ramadan App - Calender</title>
				<meta
					name="description"
					content="calendar"
				/>
				<meta
					property="og:url"
					content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html"
				/>
				<meta
					property="og:type"
					content="article"
				/>
				<meta
					property="og:title"
					content="When Great Minds Don’t Think Alike"
				/>
				<meta
					property="og:description"
					content="How much does culture influence creative thinking?"
				/>
				<meta
					property="og:image"
					content="http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg"
				/>
			</Helmet>
			{loading && <Loading />}
			{!loading && (
				<div className="calender">
					<div className="calender_container">
						<div className="calender_content">
							<table
								className={`calender_table ${
									tab === 1 ? 'active-table' : ''
								}`}
							>
								<thead onClick={() => setTab(1)}>
									<tr className="table_heading">
										<th
											colSpan={tab === 1 ? 4 : 3}
											className={`table_title ${
												tab === 1 ? 'thead-center' : ''
											}`}
										>
											{language === 'default'
												? '1st 10 Days of Rahmah'
												: 'রহমতের ১০ দিন'}
										</th>

										{tab !== 1 && (
											<th>
												<DownIcon />
											</th>
										)}
									</tr>
								</thead>
								{tab === 1 && (
									<tbody className="calender_table_body">
										{allTimes
											?.slice(0, 10)
											?.map((time, index) => (
												<DateRow
													key={index}
													serial={index + 1}
													date={new Date(time.date)}
													sehri={increaseMinutes(
														timeInDateFormat(
															new Date(time.date),
															time.sehri
														),
														timeDifference
													)}
													iftar={increaseMinutes(
														timeInDateFormat(
															new Date(time.date),
															time.iftar
														),
														timeDifference
													)}
												/>
											))}
									</tbody>
								)}
							</table>
							<table
								className={`calender_table ${
									tab === 2 ? 'active-table' : ''
								}`}
							>
								<thead onClick={() => setTab(2)}>
									<tr className="table_heading">
										<th
											colSpan={tab === 2 ? 4 : 3}
											className={`table_title ${
												tab === 2 ? 'thead-center' : ''
											}`}
										>
											{language === 'default'
												? '2nd 10 Days of Maghfirah'
												: 'মাগফিরাতের ১০ দিন'}
										</th>
										{tab !== 2 && (
											<th>
												<DownIcon />
											</th>
										)}
									</tr>
								</thead>
								{tab === 2 && (
									<tbody className="calender_table_body">
										{allTimes
											?.slice(10, 20)
											?.map((time, index) => (
												<DateRow
													key={index}
													serial={index + 11}
													date={new Date(time.date)}
													sehri={increaseMinutes(
														timeInDateFormat(
															new Date(time.date),
															time.sehri
														),
														timeDifference
													)}
													iftar={increaseMinutes(
														timeInDateFormat(
															new Date(time.date),
															time.iftar
														),
														timeDifference
													)}
												/>
											))}
									</tbody>
								)}
							</table>
							<table
								className={`calender_table ${
									tab === 3 ? 'active-table' : ''
								}`}
							>
								<thead onClick={() => setTab(3)}>
									<tr className="table_heading">
										<th
											colSpan={tab === 3 ? 4 : 3}
											className={`table_title ${
												tab === 3 ? 'thead-center' : ''
											}`}
										>
											{language === 'default'
												? 'Last 10 Days of Nijah'
												: 'নাজাতের ১০ দিন'}
										</th>
										{tab !== 3 && (
											<th>
												<DownIcon />
											</th>
										)}
									</tr>
								</thead>
								{tab === 3 && (
									<tbody className="calender_table_body">
										{allTimes
											?.slice(20, 29)
											?.map((time, index) => (
												<DateRow
													key={index}
													serial={index + 21}
													date={new Date(time.date)}
													sehri={increaseMinutes(
														timeInDateFormat(
															new Date(time.date),
															time.sehri
														),
														timeDifference
													)}
													iftar={increaseMinutes(
														timeInDateFormat(
															new Date(time.date),
															time.iftar
														),
														timeDifference
													)}
												/>
											))}
									</tbody>
								)}
							</table>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Calender;
