import { useContext, useEffect, useState } from "react"
import District from "./District/District"
import axios from "axios"
import { DISTRICTS_API } from "../../../../../../Utilities/APIs"
import AppContext from "../../../../../../store/appCtx"

function DistrictList({selectedDivision, setShowModal}) {

    const [districts, setDistricts] = useState(null)

    const {language} = useContext(AppContext)

    useEffect(() => {
        async function getAndSetDistricts() {
            const {data} = await axios.get(DISTRICTS_API + 'getDistrictsFromDivision/' + selectedDivision)
            setDistricts(data)
        }
        getAndSetDistricts()
    }, [selectedDivision])

    return (
        <tbody className="division_table_body">
            <tr>
                <td>
                    {/* <div>
                        <span className='text'>Ghulsan</span>
                        <span className='radio'></span>
                    </div>
                    <div className='active'>
                        <span className='text'>Baridhara</span>
                        <span className='radio'></span>
                    </div> */}
                    {
                        districts?.map(district => (
                            <District
                                key={district._id}
                                district={district._id}
                                name={
                                    language === 'default'
                                    ?
                                    district.name
                                    :
                                    district.bngName
                                }
                                setShowModal={setShowModal}
                            />
                        ))
                    }
                </td>
            </tr>
        </tbody>
    )
}

export default DistrictList