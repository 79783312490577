import DownIcon from '../../../../svg/DownIcon';
import DistrictList from './DistrictList/DistrictList';

function DivisionWithDistricts({name, selectedDivision, setSelectedDivision, division, setShowModal}) {
    return (
        <table
            className={`calender_table ${selectedDivision === division ? 'active-table' : ''
                }`}
        >
            <thead onClick={() => setSelectedDivision(division)}>
                <tr className="table_heading">
                    <th
                        colSpan={selectedDivision === division ? 4 : 3}
                        className={`table_title ${selectedDivision === division ? 'thead-center' : ''
                            }`}
                    >
                        {name}
                    </th>

                    {selectedDivision !== division && (
                        <th>
                            <DownIcon />
                        </th>
                    )}
                </tr>
            </thead>
            {selectedDivision === division && (
                // <tbody className="division_table_body">
                //     <tr>
                //         <td>
                //             <div>
                //                 <span className='text'>Ghulsan</span>
                //                 <span className='radio'></span>
                //             </div>
                //             <div className='active'>
                //                 <span className='text'>Baridhara</span>
                //                 <span className='radio'></span>
                //             </div>
                //         </td>
                //     </tr>
                // </tbody>
                <DistrictList
                    selectedDivision={selectedDivision}
                    setShowModal={setShowModal}
                />
            )}
        </table>
    )
}

export default DivisionWithDistricts