import { useLocation, useNavigate } from 'react-router-dom';
import image2 from '../../../../Assets/calendar.png';
import image3 from '../../../../Assets/dua.png';
import image1 from '../../../../Assets/mosque.png';

import { useContext } from 'react';
import AppContext from '../../../../store/appCtx';
import './NavFooter.css';

function NavFooter() {
	const navigate = useNavigate();
	const { loading, language, setLoading } = useContext(AppContext);
	const { pathname } = useLocation();
	const routeChange = (type) => {
		setLoading(true);
		setTimeout(() => {
			navigate(type);
		}, 1000);
	};
	return (
		<div className="nav_footer">
			<div className="nav_footer_items">
				<button
					onClick={() => {
						routeChange('/');
					}}
					disabled={loading}
					className={`nav_footer_item ${
						pathname === '/' ? 'active' : ''
					}`}
				>
					<img
						src={image1}
						alt="icon"
					/>
					<span>{language === 'default' ? 'Home' : 'হোম'}</span>
				</button>
				<button
					onClick={() => {
						routeChange('/calendar');
					}}
					disabled={loading}
					className={`nav_footer_item ${
						pathname === '/calendar' ? 'active' : ''
					}`}
				>
					<img
						src={image2}
						alt="icon"
					/>
					<span>
						{language === 'default' ? 'Calendar' : 'ক্যালেন্ডার'}
					</span>
				</button>
				<button
					onClick={() => {
						routeChange('/duas');
					}}
					disabled={loading}
					className={`nav_footer_item ${
						pathname === '/duas' ? 'active' : ''
					}`}
				>
					<img
						src={image3}
						alt="icon"
					/>
					<span>{language === 'default' ? 'Dua’s' : 'দোয়া'}</span>
				</button>
			</div>
		</div>
	);
}

export default NavFooter;
