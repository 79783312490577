export function IconLocation() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <g>
                <path d="M6.00017 0C4.03805 0 2.43005 1.6032 2.43005 3.55992C2.43005 4.31808 2.67221 5.02272 3.08201 5.60184L5.56445 9.8934C5.91209 10.3476 6.14321 10.2613 6.43229 9.8694L9.17045 5.2098C9.22565 5.10972 9.26909 5.00316 9.30689 4.89432C9.48065 4.4709 9.57 4.01761 9.56993 3.55992C9.57005 1.6032 7.96253 0 6.00017 0ZM6.00017 1.668C7.05689 1.668 7.89713 2.50632 7.89713 3.55992C7.89713 4.61352 7.05689 5.45148 6.00017 5.45148C4.94357 5.45148 4.10297 4.61364 4.10297 3.55992C4.10297 2.50632 4.94357 1.66812 6.00017 1.66812V1.668Z" fill="#99BED7" />
                <path d="M8.26955 5.86914L8.26379 5.88426C8.26559 5.8797 8.26703 5.87502 8.26883 5.87046L8.26955 5.86914Z" fill="#99BED7" />
                <path d="M4.08067 8.28662C2.38555 8.52614 1.19995 9.09914 1.19995 9.94262C1.19995 11.0788 3.18091 11.9998 5.99995 11.9998C8.81899 11.9998 10.8 11.0788 10.8 9.94262C10.8 9.09914 9.61447 8.52614 7.91947 8.28662L7.68463 8.68646C8.96215 8.8637 9.83995 9.23618 9.83995 9.66842C9.83995 10.2744 8.12071 10.7656 5.99995 10.7656C3.87919 10.7656 2.15995 10.2744 2.15995 9.66842C2.15983 9.23762 3.03187 8.8655 4.31335 8.68754C4.23595 8.55386 4.15819 8.42042 4.08067 8.28662Z" fill="#99BED7" />
            </g>
        </svg>
    )
}