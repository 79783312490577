import { useContext } from 'react';
import AppContext from '../../../../store/appCtx';
import './DateRow.css';

function DateRow({ serial, date, sehri, iftar }) {
	const { language } = useContext(AppContext);
	return (
		<tr
			className={
				date.toDateString() === new Date().toDateString()
					? 'todays_date'
					: ''
			}
		>
			<td>{new Intl.NumberFormat(language).format(serial)}</td>
			<td>
				{new Intl.DateTimeFormat(language, {
					dateStyle: 'full',
				}).format(date)}
			</td>
			<td>
				{new Intl.DateTimeFormat(language, {
					hour: '2-digit',
					minute: '2-digit',
					hour12: true,
				}).format(sehri)}
			</td>
			<td>
				{new Intl.DateTimeFormat(language, {
					hour: '2-digit',
					minute: '2-digit',
					hour12: true,
				}).format(iftar)}
			</td>
		</tr>
	);
}

export default DateRow;
