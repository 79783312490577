function timeInDateFormat(date, time) {
    const timeFormat = new Intl.DateTimeFormat('sv-SE', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(date) + 'T' + time + ':00'
    const timeInDateFormat = new Date(timeFormat)
    return timeInDateFormat
}

function increaseMinutes(date, minutes) {
    // const dateToIncrease = date
    // return dateToIncrease?.setMinutes(date.getMinutes() + parseInt(minutes))
    const ajaira = date?.setMinutes(date?.getMinutes() + parseInt(minutes))
    // console.log(ajaira)
    if (ajaira) {
        return date
    }
}

export {timeInDateFormat, increaseMinutes}