import { createContext, useEffect, useState } from 'react';

const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {

	const [loading, setLoading] = useState(true);
	const [language, setLanguage] = useState('default');
	const [toggleFetch, setToggleFetch] = useState(false);
	const [selectedDistrict, setSelectedDistrict] = useState('');
	console.log("🚀 ~ file: appCtx.js:11 ~ AppContextProvider ~ selectedDistrict:", selectedDistrict)

	const triggerFetch = () => {
		setToggleFetch((prev) => !prev);
	};

	useEffect(() => {
		localStorage.setItem('language', language);
	}, [language]);

	useEffect(() => {
		localStorage.setItem('selectedDistrict', selectedDistrict);
	}, [selectedDistrict]);

	return (
		<AppContext.Provider
			value={{
				loading,
				setLoading,
				language,
				setLanguage,
				toggleFetch,
				triggerFetch,
				selectedDistrict,
				setSelectedDistrict
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppContext;
