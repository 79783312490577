import Lottie from 'react-lottie';

import loadingAnim from './lampLoading.json';

import './Loading.css';

function Loading() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loadingAnim,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<div className="loading_max">
			<div>
			<Lottie options={defaultOptions} />
			</div>
		</div>
	);
}

export default Loading;
