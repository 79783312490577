import axios from 'axios';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DISTRICTS_API, RAMADAN_TIMES_API } from '../../../../Utilities/APIs';
import {
	increaseMinutes,
	timeInDateFormat,
} from '../../../../Utilities/Functions/TimeInDateFormat';
import AppContext from '../../../../store/appCtx';
import Loading from '../../../Partials/Loading/Loading';
import PrayerTime from './PrayerTime/PrayerTime';
import './RamadanTime.css';
import Iftar from './SeheriAndIftar/Iftar';
import Seheri from './SeheriAndIftar/Seheri';

function RamadanTime() {
	const { loading, toggleFetch, setLoading, selectedDistrict } =
		useContext(AppContext);
	const [todaysSehri, setTodaysSehri] = useState(null);
	console.log(
		'🚀 ~ file: RamadanTime.js:16 ~ RamadanTime ~ todaysSehri:',
		todaysSehri
	);
	const [tomorrowsSehri, setTomorrowsSehri] = useState(null);
	const [todaysIftar, setTodaysIftar] = useState(null);
	const [tomorrowsIftar, setTomorrowsIftar] = useState(null);

	const [todaysFajr, setTodaysFajr] = useState(null);
	const [todaysDhuhr, setTodaysDhuhr] = useState(null);
	const [todaysAsr, setTodaysAsr] = useState(null);
	const [todaysMaghrib, setTodaysMaghrib] = useState(null);
	const [todaysIsha, setTodaysIsha] = useState(null);

	const [tomorrowsFajr, setTomorrowsFajr] = useState(null);

	const rightNow = useMemo(() => new Date(), []);
	const tomorrowsDate = useMemo(
		() => new Date(new Date().setDate(new Date().getDate() + 1)),
		[]
	);

	const [timeDifference, setTimeDifference] = useState(0);

	useEffect(() => {
		async function getAndSetDistrictDetails() {
			const { data } = await axios.get(DISTRICTS_API + selectedDistrict);
			setTimeDifference(data.timeDifference);
		}

		getAndSetDistrictDetails();
	}, [selectedDistrict]);

	useEffect(() => {
		async function getAndSetTodayTomorrowTimes() {
			const { data } = await axios.get(
				RAMADAN_TIMES_API +
					'todayTomorrow/' +
					new Intl.DateTimeFormat('sv-SE', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
					}).format(new Date())
			);

			setTodaysSehri(
				increaseMinutes(
					timeInDateFormat(rightNow, data.today.sehri),
					timeDifference
				)
			);
			setTomorrowsSehri(
				increaseMinutes(
					timeInDateFormat(tomorrowsDate, data.tomorrow.sehri),
					timeDifference
				)
			);
			setTodaysIftar(
				increaseMinutes(
					timeInDateFormat(rightNow, data.today.iftar),
					timeDifference
				)
			);
			setTomorrowsIftar(
				increaseMinutes(
					timeInDateFormat(tomorrowsDate, data.tomorrow.iftar),
					timeDifference
				)
			);

			setTodaysFajr(
				increaseMinutes(
					timeInDateFormat(rightNow, data.today.fajr),
					timeDifference
				)
			);
			setTodaysDhuhr(
				increaseMinutes(
					timeInDateFormat(rightNow, data.today.dhuhr),
					timeDifference
				)
			);
			setTodaysAsr(
				increaseMinutes(
					timeInDateFormat(rightNow, data.today.asr),
					timeDifference
				)
			);
			setTodaysMaghrib(
				increaseMinutes(
					timeInDateFormat(rightNow, data.today.maghrib),
					timeDifference
				)
			);
			setTodaysIsha(
				increaseMinutes(
					timeInDateFormat(rightNow, data.today.isha),
					timeDifference
				)
			);
			setTomorrowsFajr(
				increaseMinutes(
					timeInDateFormat(tomorrowsDate, data.tomorrow.fajr),
					timeDifference
				)
			);

			setLoading(false);
		}

		getAndSetTodayTomorrowTimes();
	}, [rightNow, tomorrowsDate, setLoading, toggleFetch, timeDifference]);

	return (
		<>
			<Helmet>
				<title>Ramadan App - Home</title>
				<meta
					name="description"
					content="home"
				/>
			</Helmet>
			{loading && <Loading />}
			{!loading &&
				rightNow &&
				tomorrowsDate &&
				todaysSehri &&
				tomorrowsSehri &&
				todaysIftar &&
				tomorrowsIftar &&
				todaysFajr &&
				todaysDhuhr &&
				todaysAsr &&
				todaysMaghrib &&
				todaysIsha &&
				tomorrowsFajr && (
					<div className="RamadanTime">
						<div className="ramadan_time_wrapper">
							<div className="time_wrapper">
								<div className="time_container">
									<div className="time_left">
										{todaysSehri && tomorrowsSehri && (
											<Seheri
												time={
													rightNow.getTime() <
													todaysSehri.getTime()
														? todaysSehri
														: tomorrowsSehri
												}
												notNext={
													rightNow.getTime() >
														todaysSehri.getTime() &&
													rightNow.getTime() <
														todaysIftar.getTime()
												}
											/>
										)}
										{todaysIftar && tomorrowsIftar && (
											<Iftar
												time={
													rightNow.getTime() <
													todaysIftar.getTime()
														? todaysIftar
														: tomorrowsIftar
												}
												notNext={
													(rightNow.getTime() >
														todaysIftar.getTime() &&
														rightNow.getTime() <
															tomorrowsSehri.getTime()) ||
													rightNow.getTime() <
														todaysSehri.getTime()
												}
											/>
										)}
									</div>
									<div className="time_right">
										<PrayerTime
											todaysFajr={todaysFajr}
											todaysDhuhr={todaysDhuhr}
											todaysAsr={todaysAsr}
											todaysMaghrib={todaysMaghrib}
											todaysIsha={todaysIsha}
											tomorrowsFajr={tomorrowsFajr}
											rightNow={rightNow}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
		</>
	);
}

export default RamadanTime;
