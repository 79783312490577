import { useContext } from 'react';
import AppContext from '../../../../../store/appCtx';
import './PrayerTime.css';
import SinglePrayerTime from './SinglePrayerTime';
import TomorrowPayerTime from './TomorrowPayerTime';

function PrayerTime({
	rightNow,
	todaysFajr,
	todaysDhuhr,
	todaysAsr,
	todaysMaghrib,
	todaysIsha,
	tomorrowsFajr,
}) {
	const { language } = useContext(AppContext);
	const english = language === 'default';
	return (
		<div className="prayer-time">
			<div className="container">
				<p className="box_heading">
					{english ? 'Prayer Times' : 'নামাজের সময়'}
				</p>
				<div className="dashed-border py-border" />
				<div>
					<div className="body_footer">
						<p className="box_date">
							{new Intl.DateTimeFormat(language, {
								dateStyle: 'full',
							}).format(new Date())}
						</p>
						<h6 className="box_time">
							{english ? 'Today' : 'আজকে'}
						</h6>
					</div>
					<div className="daily-prayer">
						<SinglePrayerTime
							name={english ? 'Fajr' : 'ফজর'}
							time={todaysFajr}
							current={rightNow.getTime() < todaysFajr?.getTime()}
						/>
						<SinglePrayerTime
							name={english ? 'Dhuhr' : 'জোহর'}
							time={todaysDhuhr}
							current={
								rightNow.getTime() > todaysFajr?.getTime() &&
								rightNow.getTime() < todaysDhuhr?.getTime()
							}
						/>
						<SinglePrayerTime
							name={english ? 'Asr' : 'আসর'}
							time={todaysAsr}
							current={
								rightNow.getTime() > todaysDhuhr?.getTime() &&
								rightNow.getTime() < todaysAsr?.getTime()
							}
						/>
						<SinglePrayerTime
							name={english ? 'Maghrib' : 'মাগরিব'}
							time={todaysMaghrib}
							current={
								rightNow.getTime() > todaysAsr?.getTime() &&
								rightNow.getTime() < todaysMaghrib?.getTime()
							}
						/>
						<SinglePrayerTime
							name={english ? 'Isha' : 'এশা'}
							time={todaysIsha}
							current={
								rightNow.getTime() > todaysMaghrib?.getTime() &&
								rightNow.getTime() < todaysIsha?.getTime()
							}
						/>
					</div>
				</div>
				<div className="dashed-border py-border" />
				<TomorrowPayerTime
					tomorrowsFajr={tomorrowsFajr}
					rightNow={rightNow}
					todaysIsha={todaysIsha}
				/>
			</div>
		</div>
	);
}

export default PrayerTime;
