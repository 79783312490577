import { useContext } from "react";
import AppContext from "../../../../../../../store/appCtx";

function District({name, district, setShowModal}) {

    const { setSelectedDistrict } = useContext(AppContext);

    return (
        <div
            onClick={() => {
                setSelectedDistrict(district)
                setShowModal(false)
            }}
        >
            <span className='text'>{name}</span>
            <span className='radio'></span>
        </div>
    )
}

export default District