import { intervalToDuration } from 'date-fns';
import { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import AppContext from '../../../../../store/appCtx';
import SeheriAndIftar from './SeheriAndIftar';

function Seheri({ time, notNext }) {
	const navigate = useNavigate();
	const { language } = useContext(AppContext);
	const [remainingTime, setRemainingTime] = useState(
		intervalToDuration({
			start: new Date(),
			end: time,
		})
	);

	useEffect(() => {
		const interval = setInterval(() => {
			if (time.getTime() - new Date().getTime() < 0) {
				navigate(0);
			} else {
				setRemainingTime(
					intervalToDuration({
						start: new Date(),
						end: time,
					})
				);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [time, navigate]);

	return (
		<>
			<SeheriAndIftar
				heading={language === 'default' ? 'Sehri' : 'সেহরি'}
				hours={remainingTime?.hours}
				minutes={remainingTime?.minutes}
				seconds={remainingTime?.seconds}
				time={time}
				notNext={notNext}
			/>
		</>
	);
}

export default Seheri;
