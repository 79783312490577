import { useContext } from 'react';
import AppContext from '../../../store/appCtx';
import './Language.css';

function Language() {
	const { language, setLanguage } = useContext(AppContext);

    function handleClick () {
        if(language === 'default') {
            setLanguage('bn-BD')
        }
        if(language === 'bn-BD') {
            setLanguage('default')
        }
    }

	return (
		<div className="language" onClick={handleClick}>
			<button
				type="button"
				className={language === 'bn-BD' ? 'active' : ''}
			>
				<span>বাং</span>
			</button>
			<button
				type="button"
				className={language === 'default' ? 'active' : ''}
			>
				<span>En</span>
			</button>
		</div>
	);
}

export default Language;
