import { useContext } from 'react';
import AppContext from '../../../../../store/appCtx';
import Remaining from '../Components/Remaining/Remaining';
import './SeheriAndIftar.css';

function SeheriAndIftar({ heading, hours, minutes, seconds, time, notNext }) {
	const { language } = useContext(AppContext);

	const timeToFormat = new Intl.DateTimeFormat(language, {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	}).format(time)

	return (
		<div className="seheri_and_iftar_box">
			<div
				className="container"
				style={
					notNext
						? { filter: 'brightness(0.6)' }
						: { filter: 'brightness(1)' }
				}
			>
				<p className="box_heading">{heading}</p>
				<Remaining
					hours={hours}
					minutes={minutes}
					seconds={seconds}
				/>
				<div className="body_footer">
					<p className="box_date">
						{new Intl.DateTimeFormat(language, {
							dateStyle: 'full',
						}).format(time)}
					</p>
					<p className="box_time">
						{/* {new Intl.DateTimeFormat(language, {
							hour: '2-digit',
							minute: '2-digit',
							hour12: true,
						}).format(time)} */}
						{timeToFormat.slice(0, 6)}
						<span>{timeToFormat.slice(6, 8)}</span>
					</p>
				</div>
			</div>
		</div>
	);
}

export default SeheriAndIftar;
