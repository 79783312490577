import Lottie from 'react-lottie';

import hedingAnim from './heading.json';

// import HeaderImg from '../../svg/HeaderImg';

import './Header.css';

function Header() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: hedingAnim,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<header className="main_header">
			<Lottie options={defaultOptions} />
		</header>
	);
}

export default Header;
