import { useContext, useEffect, useState } from 'react'
import { IconLocation } from './IconLocation'
import './Location.css'
import AppContext from '../../../store/appCtx'
import axios from 'axios'
import { DISTRICTS_API } from '../../../Utilities/APIs'

function Location({ handleClick }) {

    const [districtName, setDistrictName] = useState(null)
    const {selectedDistrict, language} = useContext(AppContext)

    useEffect(() => {

        async function getAndSetDistrictDetails() {
            const {data} = await axios.get(DISTRICTS_API + selectedDistrict)
            console.log(data)
            if(language === 'default') {
                setDistrictName(data.name)
            } else {
                setDistrictName(data.bngName)
            }
        }

        getAndSetDistrictDetails()
        
    }, [selectedDistrict, language])

    return (
        <button
            type='button'
            className='btn_location'
            onClick={handleClick}
        >
            {districtName && <span>{districtName}</span>}
            <IconLocation />
        </button>
    )
}

export default Location