// import axios from 'axios';
import { useContext, useState } from 'react';
// import { useContext, useEffect, useState } from 'react';
// import { RAMADAN_TIMES_API } from '../../../Utilities/APIs';
import AppContext from '../../../store/appCtx';
import Language from '../Language/Language';
import Location from '../Location/Location';
import Modal from '../Modal/Modal';
import DivisionLists from '../Location/DivisionLists/DivisionLists';

const Controls = () => {

	const [showModal, setShowModal] = useState(false);

	const { language } = useContext(AppContext);
	// const [day, setDay] = useState(null);
	// const convertDate = () => {
	// 	return new Intl.NumberFormat(language).format(day);
	// };

	// useEffect(() => {
	// 	const currentDateFind = async () => {
	// 		const { data } = await axios.get(RAMADAN_TIMES_API);
	// 		const today =
	// 			data.findIndex(
	// 				(item) =>
	// 					new Date(item.date).toDateString() ===
	// 					new Date().toDateString()
	// 			) + 1;
	// 		setDay(today);
	// 	};
	// 	currentDateFind();
	// }, []);

	function handleClose() {
		setShowModal(false)
	}


	return (
		<>
			<div className="location_language">
				<Location handleClick={() => setShowModal(true)} />
				{/* <span className="day_overview">
					{language === 'default'
						? `Ramadan Day ${convertDate()}`
						: `${convertDate()} তম রমজান`}
				</span> */}
				<Language />
			</div>
			{showModal &&
				<Modal
					handleClose={handleClose}
					modalHeading={language === 'default' ? 'Select Location' : 'লোকেশন সিলেক্ট করুন'}
				>
					<DivisionLists setShowModal={setShowModal} />

				</Modal>
			}
		</>
	);
};

export default Controls;
