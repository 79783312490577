import { useContext } from 'react';
import AppContext from '../../../../../store/appCtx';
import SinglePrayerTime from './SinglePrayerTime';

function TomorrowPayerTime({ tomorrowsFajr, rightNow, todaysIsha }) {
	const { language } = useContext(AppContext);
	const english = language === 'default';
	return (
		<div className="tomorrow-prayer">
			<div className="body_footer">
				<p className="box_date">
					{new Intl.DateTimeFormat(language, {
						dateStyle: 'full',
					}).format(
						new Date(new Date().setDate(new Date().getDate() + 1))
					)}
				</p>
				<h6 className="box_time">
					{english ? 'Tomorrow' : 'আগামীকাল'}
				</h6>
			</div>
			<SinglePrayerTime
				name={english ? 'Fajr' : 'ফজর '}
				time={tomorrowsFajr}
				current={rightNow.getTime() > todaysIsha?.getTime()}
			/>
		</div>
	);
}

export default TomorrowPayerTime;
