import { useContext } from 'react';
import AppContext from '../../../../../../store/appCtx';
import './Remaining.css';

function Remaining({ hours, minutes, seconds }) {
	const { language } = useContext(AppContext);
	const convertNumber = (time) => {
		return new Intl.NumberFormat(language).format(time);
	};
	const english = language === 'default';
	return (
		<div>
			<p className="remaining_time">
				{convertNumber(hours)}
				{english ? 'h' : 'ঘ'} {convertNumber(minutes)}
				{english ? 'm' : 'মি'}
			</p>
			<p className="remaining_time">
				{convertNumber(seconds)}
				{english ? 's' : 'সে'}
			</p>
			<p className="remaining_text">{english ? 'Remaining' : 'বাকি'}</p>
		</div>
	);
}

export default Remaining;
