// import { useState } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';

import { useContext, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import AppContext from '../../store/appCtx';
import Controls from '../Partials/Controls/Controls';
import NavFooter from '../Partials/Footer/NavFooter/NavFooter';
import Header from '../Partials/Header/Header';
import LargeView from '../Partials/LargeView/LargeView';
import Calender from './Calender/Calender';
import Dua from './Dua/Dua';
import RamadanTime from './Home/RamadanTimeBody/RamadanTime';

function App() {
	const TRACKING_ID = 'G-YXXFR9XQEV'; // OUR_TRACKING_ID
	ReactGA.initialize(TRACKING_ID);
	const { language, setLanguage, setSelectedDistrict } = useContext(AppContext);

	useEffect(() => {

		ReactGA.pageview(window.location.pathname + window.location.search);
		
	}, []);

	useEffect(() => {

		const localLanguage = localStorage.getItem('language');
		setLanguage(localLanguage ?? 'default');

	}, [setLanguage])

	useEffect(() => {

		const localDistrict = localStorage.getItem('selectedDistrict');
		setSelectedDistrict(localDistrict ?? '62e743de69cf83b0667905f1')

	}, [setSelectedDistrict])

	return (
		<HelmetProvider>
			<BrowserRouter>
				<main className={language === 'bn-BD' ? 'bn' : 'default'}>
					<Header />
					<Controls />
					<Routes>
						<Route
							path="/"
							element={<RamadanTime />}
						/>
						<Route
							path="/calendar"
							element={<Calender />}
						/>
						<Route
							path="/duas"
							element={<Dua />}
						/>
					</Routes>
					<NavFooter />
				</main>
				<LargeView />
			</BrowserRouter>
		</HelmetProvider>
	);
}

export default App;
