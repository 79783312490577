import { useContext, useEffect, useState } from 'react';
import DownIcon from '../../svg/DownIcon';

import { Helmet } from 'react-helmet-async';
import AppContext from '../../../store/appCtx';
import Loading from '../../Partials/Loading/Loading';
import './Dua.css';

function Dua() {
	const [tab, setTab] = useState(1);
	const { loading, language, setLoading } = useContext(AppContext);

	const handleTab = (type = 1) => {
		setTab(type);
	};

	useEffect(() => {
		setLoading(false);
	}, [setLoading]);

	return (
		<>
			<Helmet>
				<title>Ramadan App - Duas</title>
				<meta
					name="description"
					content="dua"
				/>
			</Helmet>
			{loading && <Loading />}
			{!loading && (
				<div className="calender">
					<div className="calender_container">
						<div className="calender_content">
							<table
								className={`calender_table ${
									tab === 1 ? 'active-table' : ''
								}`}
							>
								<thead onClick={() => handleTab(1)}>
									<tr className="table_heading">
										<th
											colSpan={tab === 1 ? 4 : 3}
											className={`table_title ${
												tab === 1 ? 'thead-center' : ''
											}`}
										>
											{language === 'default'
												? 'Sehri Dua'
												: 'সেহরি দোয়া'}
										</th>

										{tab !== 1 && (
											<th>
												<DownIcon />
											</th>
										)}
									</tr>
								</thead>
								{tab === 1 && (
									<tbody className="dua_table_body">
										<tr>
											<td>
												<p className="arabic">
													نَوَيْتُ اَنْ اُصُوْمَ غَدًا
													مِّنْ شَهْرِ رَمْضَانَ
													الْمُبَارَكِ فَرْضَا لَكَ
													يَا اللهُ فَتَقَبَّل مِنِّى
													اِنَّكَ اَنْتَ السَّمِيْعُ
													الْعَلِيْم
												</p>
												<p>
													<span>
														{language === 'default'
															? 'Pronunciation'
															: 'উচ্চারণ'}
														: &nbsp;
													</span>
													{language === 'default'
														? `Nauaitu an asuma gadammin
														shahri ramdanal mubaraki
														fardallaka ya Allahu
														fatakakabbal minni innaka
														antas samiul alim`
														: 'নাওয়াইতু আন আছুম্মা গাদাম মিন শাহরি রমাজানাল মুবারাকি ফারদাল্লাকা, ইয়া আল্লাহু ফাতাকাব্বাল মিন্নি ইন্নিকা আনতাস সামিউল আলিম।'}
												</p>
												<p>
													<span>
														{language === 'default'
															? 'Meaning'
															: 'বাংলা অর্থ'}
														: &nbsp;
													</span>
													{language === 'default'
														? `Oh Allah! In the holy month
														of Ramadan, I intend to
														perform the Siyam that you
														made Farz tomorrow. Please
														accept my fasting
														(abstinence from food and
														drink). Surely you are the
														All-Hearing and the
														All-Knowing.`
														: 'হে আল্লাহ! আগামীকাল পবিত্র রমযান মাসে তোমার পক্ষ হতে ফরয করা রোজা রাখার ইচ্ছা পোষণ (নিয়্যত) করলাম, অতএব তুমি আমার পক্ষ হতে (আমার রোযা তথা পানাহার থেকে বিরত থাকাকে) কবুল কর, নিশ্চয়ই তুমি সর্বশ্রোতা ও সর্বজ্ঞানী।'}
												</p>
											</td>
										</tr>
									</tbody>
								)}
							</table>
							<table
								className={`calender_table ${
									tab === 2 ? 'active-table' : ''
								}`}
							>
								<thead onClick={() => handleTab(2)}>
									<tr className="table_heading">
										<th
											colSpan={tab === 2 ? 4 : 3}
											className={`table_title ${
												tab === 2 ? 'thead-center' : ''
											}`}
										>
											{language === 'default'
												? 'Iftar Dua'
												: 'ইফতার দোয়া'}
										</th>
										{tab !== 2 && (
											<th>
												<DownIcon />
											</th>
										)}
									</tr>
								</thead>
								{tab === 2 && (
									<tbody className="dua_table_body">
										<tr>
											<td>
												<p className="arabic">
													اَللَّهُمَّ لَكَ صُمْتُ وَ
													عَلَى رِزْقِكَ وَ اَفْطَرْتُ
													بِرَحْمَتِكَ يَا اَرْحَمَ
													الرَّاحِيْمِيْن
												</p>
												<p>
													<span>
														{language === 'default'
															? 'Pronunciation'
															: 'উচ্চারণ'}
														: &nbsp;
													</span>
													{language === 'default'
														? `Allahumma laka sumtu wa ala
														rizkika aftartu birahmatika
														ya arhamar rahimeen`
														: 'নাওয়াইতু আন আছুম্মা গাদাম মিন শাহরি রমাজানাল মুবারাকি ফারদাল্লাকা, ইয়া আল্লাহু ফাতাকাব্বাল মিন্নি ইন্নিকা আনতাস সামিউল আলিম।'}
												</p>
												<p>
													<span>
														{language === 'default'
															? 'Meaning'
															: 'বাংলা অর্থ'}
														: &nbsp;
													</span>

													{language === 'default'
														? `Oh Allah! For your
														satisfaction I have fasted
														and with Your provisions do
														I break my fast.`
														: 'হে আল্লাহ! আগামীকাল পবিত্র রমযান মাসে তোমার পক্ষ হতে ফরয করা রোজা রাখার ইচ্ছা পোষণ (নিয়্যত) করলাম, অতএব তুমি আমার পক্ষ হতে (আমার রোযা তথা পানাহার থেকে বিরত থাকাকে) কবুল কর, নিশ্চয়ই তুমি সর্বশ্রোতা ও সর্বজ্ঞানী।'}
												</p>
											</td>
										</tr>
									</tbody>
								)}
							</table>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Dua;
